import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { withPrivacyPolicyProvider } from './PrivacyPolicyContext';
import { usePrivacyPolicyData } from './hooks';
import Content from './Content';

import Layout from 'components/Layout';

const PrivacyPolicy: React.FC<Omit<PageProps, 'data'>> = () => {
  const { layoutProps } = usePrivacyPolicyData();

  return (
    <Layout {...layoutProps}>
      <Content />
    </Layout>
  );
};

export const privacyPolicyFragment = graphql`
  fragment PrivacyPolicyData on PrismicPrivacyPolicy {
    ...PrivacyPolicyContentData
  }

  fragment PrivacyPolicyLayoutData on PrismicPrivacyPolicy {
    lang
    alternate_languages {
      document {
        ... on PrismicPrivacyPolicy {
          lang
          url
        }
      }
    }
  }
`;

export default withPrivacyPolicyProvider(PrivacyPolicy);
