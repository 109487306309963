import { usePrivacyPolicy } from './PrivacyPolicyContext';

import { useLayoutProps, TAlternateLanguages } from 'components/Layout';

export const usePrivacyPolicyData = () => {
  const { lang, alternate_languages } = usePrivacyPolicy().privacyPolicy;

  return {
    layoutProps: useLayoutProps({
      lang,
      alternate_languages: alternate_languages as TAlternateLanguages,
      isDark: true,
    }),
  };
};
