import { usePrivacyPolicy } from '../PrivacyPolicyContext';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useContentData = () => {
  const { title, subtitle, content } = usePrivacyPolicy().privacyPolicy.data;

  return {
    title: richTextWithPlaceholder(title, ''),
    subtitle: richTextWithPlaceholder(subtitle, ''),
    content: richTextWithPlaceholder(content, ''),
  };
};
