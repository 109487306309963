import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import PrivacyPolicy from './PrivacyPolicy';

import { withHead } from 'components/SEO';

export const Head = withHead<Queries.PrivacyPolicyQuery>('privacyPolicy');

export const query = graphql`
  query PrivacyPolicy($id: String) {
    privacyPolicy: prismicPrivacyPolicy(id: { eq: $id }) {
      ...SEOData
      ...PrivacyPolicyData
      ...PrivacyPolicyLayoutData
      _previewable
    }
  }
`;

export type AllPrivacyPoliciesQuery = Queries.AllPrivacyPoliciesQuery;

export default withPrismicPreview(PrivacyPolicy);
